const coloredButton = document.querySelector('.portfolio-button-link');
const buttonBgColors2 = [];
const buttonBgColors = [
  '#77B23D',
	'#FFE500',
	'#7C79E5',
  '#C70202',
	'#8CAF3B',
	'#2C2D37',
  '#5A0089',
  '#1F4E8D',
  '#A9D1FF',
  '#92B7C9',
  '#2C5AFF',
  '#E36D27',
  '#154E9F',
  '#0083DE',
];
const buttonTextColors2 = [];
const buttonTextColors = [
  '#ffffff',
	'#000000',
	'#ffffff',
	'#ffffff',
	'#ffffff',
  '#ffffff',
  '#ffffff',
  '#ffffff',
  '#ffffff',
  '#ffffff',
  '#ffffff',
  '#ffffff',
  '#ffffff',
  '#ffffff',
];
document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.portfolio-page').forEach(item => {
    buttonBgColors2.push(item.dataset.bg);
    buttonTextColors2.push(item.dataset.color);
		coloredButton.parentNode.style.background = buttonBgColors2[0];
		coloredButton.style.color = buttonTextColors2[0];

  })
  console.log(buttonBgColors2);
	// Смена цвета фиксированной кнопки в списке портфолио
	// Когда кнопка попадает на блок раздела, фон кнопки и цвет текста меняются
	let portfolioPages = document.querySelectorAll('.portfolio-page');
	// console.dir(portfolioPages);
	function changeButtonColor() {
		for(let i = 0; i < portfolioPages.length; i++) {
			if(portfolioPages[i].getBoundingClientRect().top <= portfolioPages[i].scrollHeight - 58) {
				coloredButton.parentNode.style.background = buttonBgColors2[i];
				coloredButton.style.color = buttonTextColors2[i];
			};
		}
		if(window.pageYOffset < 2) {
			coloredButton.parentNode.style.background = buttonBgColors2[0];
			coloredButton.style.color = buttonTextColors2[0];
		}
	};
	if(portfolioPages.length !== 0){
		document.addEventListener('scroll', changeButtonColor);
	}
	const menuButton = document.querySelector('.header-menu-button');
	function changeMenuButtonColor() {
		if(portfolioPages[0].getBoundingClientRect().bottom < 72) {
			menuButton.classList.remove('white-button');
		} else {
			menuButton.classList.add('white-button');
		}
	}
	if(portfolioPages.length !== 0){
		document.addEventListener('scroll', changeMenuButtonColor);
	}

	// Phone validation function.
	function phoneValidation (event) {
    let string = event.target.value;
    let value = string.slice(2).replace(/\D/g, '');
    event.target.value = '';
    let arr = value.split('');
    let rdyArr = ['+7'];
    let result = '';
    arr.forEach(function(digit, index) {
        if (index == 0) {
            rdyArr.push(' (');
        }
        if (index == 3) {
            rdyArr.push(') ');
        }
        if (index == 6 || index == 8) {
            rdyArr.push(' ');
        }
        if (index > 9) {
            return;
        }
        rdyArr.push(digit);
    })
    rdyArr.forEach(function (str) {
        result += str;
    })
    event.target.value = result;
  }

	// Add phone validation to all phone inputs.

	$('[name="phone"]').on('input change', event => {
		phoneValidation(event);
	});

	// Toggle menu in header.

  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

	function toggleMainMenu (event) {
		event.preventDefault();
		$('#site-header .header').toggleClass('popup-is-active');
		$('#site-header .header').removeClass('modal-is-active');
		$('body').toggleClass('popup-is-active');
		$('html').toggleClass('popup-is-active');
	}

	function toggleModal (event) {
		event.preventDefault();
    $('#site-header .header').toggleClass('modal-is-active');
		$('#site-header .header').removeClass('popup-is-active');
		$('body').toggleClass('popup-is-active');
		$('html').toggleClass('popup-is-active');
	}
	$('#header-menu-toggle').on('click', toggleMainMenu);
	$('.modal-callback-close').on('click', toggleModal);
	$('#modal-toggle').on('click', toggleMainMenu);
	$('.portfolio-page-link').on('click', toggleModal);
	$('.portfolio-button-link').on('click', toggleModal);
	$('.detail-page-link').on('click', toggleModal);

	// animated numbers

	function animateNumber (elem, max) {
		let i = max - 30;
		let step = 1;
		let numbersCounter = setInterval(() => {
			i += step;
			if(i <= max) {
				elem.text(i);
			} else {
				elem.text(max);
				clearInterval(numbersCounter);
			}
		}, 25);
	}
	animateNumber($('.pros-clients'), 85);
	animateNumber($('.pros-years'), 2005);
	animateNumber($('.pros-projects'), 100);

  //E-mail Ajax Send
  $('.main-form').submit(function(e) { //Change
    const input = $(this).find('input[name="phone"]');

    const th = $(this);
    if(input.val().length < 18) {
      e.preventDefault();
      input.focus();
      input.addClass('is-invalid');
    } else {
      $.ajax({
        type: "POST",
        url: "/mail.php", //Change
        data: th.serialize()
      }).done(function() {
        $('.modal-thanks').show();
        setTimeout(function() {
          // Done Functions
          $('.modal-thanks').hide();
          th.trigger("reset");
        }, 1200);
      });
      return false;
    }
  });

  $('.modal-callback-form form').submit(function(e) { //Change
    const input = $(this).find('input[name="phone"]');

    const th = $(this);

    if(input.val().length < 18) {
      e.preventDefault();
      input.focus();
      input.addClass('is-invalid');
    } else {
      $.ajax({
        type: "POST",
        url: "/mail.php", //Change
        data: th.serialize(),
      }).done(function() {
        $('.modal-thanks').show();
        setTimeout(function() {
          // Done Functions
          $('.modal-thanks').hide();
          th.trigger("reset");
        }, 1200);
      });
      return false;
    }
  });

  $('[name="phone"]').on('change, input', function(e) {
    $(e.target).removeClass('is-invalid');
  });


  // kvokna filter toggler

  let kvoknaFilter = document.querySelector('.detail-ui-tabs');

  if(kvoknaFilter) {
    kvoknaFilter.addEventListener('click', e => {
      e.preventDefault();
      if(e.target.closest('.ui-tabs-button')) {
        document.querySelectorAll('.ui-tabs-button').forEach(elem => {
          elem.classList.remove('active');
        })
        e.target.closest('.ui-tabs-button').classList.add('active');
      }
    });
  };

  // bureau filter toggler
  let bureauFilter = document.querySelector('.filter-taxation-buttons');

  if(bureauFilter) {
    bureauFilter.addEventListener('click', e => {
      e.preventDefault();
      if(e.target.closest('.filter-button')) {
        document.querySelectorAll('.filter-button').forEach(elem => {
          elem.classList.remove('active');
        })
        e.target.closest('.filter-button').classList.add('active');
      }
    })
  }


// mousemove parallax scripts by Dizorov

  const caesar = document.querySelector('.slogan-image-wrap');
  const leaves = document.querySelector('.detail-image-parallax');
  const caesarPos = {};
  const leavesPos = {};
  if(caesar) {
    caesarPos.x = window.getComputedStyle(caesar).right;
    console.log(caesarPos.x);
    caesarPos.y = window.getComputedStyle(caesar).bottom;
  }
  if(leaves) {
    leavesPos.x = window.getComputedStyle(leaves).left;
    leavesPos.y = window.getComputedStyle(leaves).top;
  }
  let startX, startY;
  const startMouseParallax = e => {

    startX = e.pageX;
    startY = e.pageY;

    window.removeEventListener('mousemove', startMouseParallax);
    window.addEventListener('mousemove', e => {
      if(caesar) caesarParallax(e, 2);
      if(leaves) leavesParallax(e, 2);
    });
  }

  const caesarParallax = (e, layer) => {
    const layerCoeff = 120 / layer;
    let x = (e.pageX - startX) / layerCoeff;
    let y = (e.pageY - startY) / layerCoeff;

    caesar.style.right = (parseInt(caesarPos.x) + x) + 'px';
    caesar.style.bottom = (parseInt(caesarPos.y) + y) + 'px';
  }

  const leavesParallax = (e, layer) => {
    const layerCoeff = 120 / layer;
    let x = -(e.pageX - startX) / layerCoeff;
    let y = -(e.pageY - startY) / layerCoeff;

    leaves.style.left = (parseInt(leavesPos.x) + x) + 'px';
    leaves.style.top = (parseInt(leavesPos.y) + y) + 'px';
  }

  window.addEventListener('mousemove', startMouseParallax);
});
